import Eev from 'eev';

class EventManager {

  static em_instance = null;
  static data_queue = {};

  constructor() {
    this.e = new Eev();
  }

  static instance() {
    if (!EventManager.em_instance) {
      EventManager.em_instance = new EventManager();
    }
    return EventManager.em_instance;
  }

  clear = () => {
    EventManager.em_instance = null;
    EventManager.data_queue = {};
  }

  on = (eventName, handler) => {
    if (EventManager.data_queue[eventName] !== undefined) {
      handler(EventManager.data_queue[eventName])
    }
    this.e.on(eventName, handler);
  }

  onNewEvent = (eventName, handler) => {
    this.e.on(eventName, handler);
  }

  off = (eventName, handler) => this.e.off(eventName, handler)

  emit = (eventName, data = null ) => {
    this.e.emit(eventName, data);
    EventManager.data_queue[eventName] = data;
  }

}

export default EventManager.instance();
