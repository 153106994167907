const importAll = (r) => r.keys().map(r)

importAll(require.context('../../images/529s/', true, /\.(ico|png|jpe?g|svg)$/));

import EventManager from '../../classes/EventManager';
window.EventManager = EventManager

// Base styles
import '../../bootstrap'
import '../../stylesheets/shared/home.styles'
import '../../styles'

// dynamic loader
import(
  /* webpackChunkName: "shared-application-pack-loader" */
  `../../packLoader/529s/shared-application`
)

import(
  /* webpackChunkName: "stimulus-controllers" */
  'controllers'
)
